import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          <span className="app-title">Application</span><br />
          <span className="app-tagline">It's time to act</span>
        </p>
      </header>
    </div>
  );
}

export default App;
